import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { HStack, VStack } from "./utils";
type LoaderProps = {
  className?: string
}

function Loader({className}: LoaderProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const data = [
    "Fetching Data...",
    "Preparing Chart...",
    "Almost there...",
    "Loading...",
  ];
  const icon = [
    "solar:chart-bold-duotone",
    "uil:chart",
    "mdi:chart-arc",
    "uis:chart",
  ];
  const length = 4;
  let count = 0;
  useEffect(() => {
    const intervalId = setInterval(() => {
      count++;
      setActiveIndex(count % length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <VStack className={`justify-center mx-auto items-center ${className}`}>
      <Icon
        className="animate-pulse text-stone-500"
        icon={icon[activeIndex]}
        width={48}
      />
      <HStack>
        <div
          className="indicator-progress animate-pulse text-stone-500"
          style={{ display: "block" }}
        >
          {data[activeIndex]}
        </div>
      </HStack>
    </VStack>
  );
}

export default Loader;
